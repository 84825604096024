import {ITEMS_ICON_URL} from "../utils/constants";

export const drawableItems: any = {
    'Hatchet': `${ITEMS_ICON_URL}human_weapon_hatchet.jpg`,
    'Saber': `${ITEMS_ICON_URL}human_weapon_saber.jpg`,
    'Battle axe': `${ITEMS_ICON_URL}human_weapon_axe.jpg`,
    'Hunting Bow': `${ITEMS_ICON_URL}human_weapon_bow.jpg`,
    'Marksman\'s Bow': `${ITEMS_ICON_URL}human_weapon_mmbow.jpg`,
    'Crossbow': `${ITEMS_ICON_URL}human_weapon_crossbow.jpg`,
    'Scattergun': `${ITEMS_ICON_URL}human_weapon_scattergun.jpg`,
    'Repeater': `${ITEMS_ICON_URL}human_weapon_repeater.jpg`,
    'Coil Rifle': `${ITEMS_ICON_URL}human_weapon_coilrifle.jpg`,
    'Discharger': `${ITEMS_ICON_URL}human_weapon_discharger.jpg`,
    'Flux Gun': `${ITEMS_ICON_URL}human_weapon_fluxgun.jpg`,
    'Pulse Cannon': `${ITEMS_ICON_URL}human_weapon_pulsegun.jpg`,
    'Incinerator': `${ITEMS_ICON_URL}human_weapon_incinerator.jpg`,
    'Mortar': `${ITEMS_ICON_URL}human_weapon_mortar.jpg`,
    'Launcher': `${ITEMS_ICON_URL}human_weapon_launcher.jpg`,
    'Ballista Weapon': `${ITEMS_ICON_URL}human_unit_ballista.jpg`,
    'Catapult Weapon': `${ITEMS_ICON_URL}human_unit_catapult.jpg`,

    'Bone sword': `${ITEMS_ICON_URL}beast_weapon_bonesword.jpg`,
    'Stalker claws': `${ITEMS_ICON_URL}beast_weapon_stalkerclaw.jpg`,
    'Predator claws': `${ITEMS_ICON_URL}beast_weapon_predatorclaw.jpg`,
    'Venomous': `${ITEMS_ICON_URL}beast_weapon_venom.jpg`,
    'Rabid': `${ITEMS_ICON_URL}beast_weapon_rabid.jpg`,
    'Carnivorous': `${ITEMS_ICON_URL}beast_weapon_carn.jpg`,
    'Ember': `${ITEMS_ICON_URL}beast_weapon_ember.jpg`,
    'Blaze': `${ITEMS_ICON_URL}beast_weapon_blaze.jpg`,
    'Fireball': `${ITEMS_ICON_URL}beast_weapon_fireball.jpg`,
    'Frost Bolts': `${ITEMS_ICON_URL}beast_weapon_frost.jpg`,
    'Tempest': `${ITEMS_ICON_URL}beast_weapon_tempest.jpg`,
    'Lightning': `${ITEMS_ICON_URL}beast_weapon_lightning.jpg`,
    'Chaos Bolt': `${ITEMS_ICON_URL}beast_weapon_chaosbolt.jpg`,
    'Surge': `${ITEMS_ICON_URL}beast_weapon_surge.jpg`,
    'Rupture': `${ITEMS_ICON_URL}beast_weapon_rupture.jpg`,
    'Summoner strike': `${ITEMS_ICON_URL}beast_unit_summoner.jpg`,
    'Uprooted Tree': `${ITEMS_ICON_URL}beast_unit_behemoth.jpg`,
}